import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import c from 'classnames'
import { Close } from '@mui/icons-material'

import styles from './PhotoModal.module.scss'

import { STORAGE } from '../../utils/consts'
import { ReducerStoreInterface } from '../../utils/interfaces'
import { setPhotoModal } from '../../store/reducers/data'

const PhotoModal = () => {
	const dispatch = useDispatch()
	const { photoModal } = useSelector(
		(reduxStore: ReducerStoreInterface) => reduxStore.data
	)

	return (
		<div
			className={c(styles.wrapper, photoModal && styles.visible)}
			onClick={() => dispatch(setPhotoModal(null))}
		>
			<div className={styles.content}>
				<div
					className={styles.closeIcon}
					onClick={() => dispatch(setPhotoModal(null))}
				>
					<Close />
				</div>
				{photoModal && (
					<img
						src={`${STORAGE}/${photoModal.id}/${photoModal.file_name}`}
						alt={photoModal.file_name}
					/>
				)}
			</div>
		</div>
	)
}

export default PhotoModal
