import { createSlice } from '@reduxjs/toolkit'

const initialStateObject = {
	loading: false,
	step: 0,
	packageSelected: 1,
	yearbookSelected: false,
	media: [],
	cart: [],
	cartAdditional: [],
	photoModal: null,
	pricelist: {
		name: '',
		price: 0,
		photos: 0,
		items: []
	},
	formData: {
		name: '',
		email: '',
		phone: '',
		address: '',
		notes: ''
	},
	totalPrice: 0,
	company: {
		account: '',
		address: '',
		name: ''
	},
	gallery: {
		id: null,
		name: '',
		slug: '',
		yearbook: null,
		expires_at: null
	},
	order: {
		id: null
	}
}

export const data = createSlice({
	name: 'data',
	initialState: initialStateObject,
	reducers: {
		setPackage: (state, action) => {
			state.packageSelected = action.payload
		},
		setStep: (state, action) => {
			state.step = action.payload
		},
		setMedia: (state, action) => {
			// @ts-ignore
			state.media = action.payload
		},
		setCart: (state, action) => {
			state.cart = action.payload
		},
		setCartAdditional: (state, action) => {
			state.cartAdditional = action.payload
		},
		setPricelist: (state, action) => {
			state.pricelist = action.payload
		},
		setFormData: (state, action) => {
			state.formData = action.payload
		},
		setTotalPrice: (state, action) => {
			state.totalPrice = action.payload
		},
		setCompany: (state, action) => {
			state.company = action.payload
		},
		setGallery: (state, action) => {
			state.gallery = action.payload
		},
		setOrder: (state, action) => {
			state.order = action.payload
		},
		setLoading: (state, action) => {
			state.loading = action.payload
		},
		setPhotoModal: (state, action) => {
			state.photoModal = action.payload
		},
		setYearbook: (state, action) => {
			state.yearbookSelected = action.payload
		},
		resetState: () => initialStateObject
	}
})

export const {
	setPackage,
	setStep,
	setMedia,
	setCart,
	setCartAdditional,
	setPricelist,
	setFormData,
	setTotalPrice,
	setCompany,
	setGallery,
	setOrder,
	resetState,
	setLoading,
	setPhotoModal,
	setYearbook
} = data.actions

export default data.reducer
