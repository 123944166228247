import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import c from 'classnames'

import {
	ArrowForwardIosOutlined,
	ArrowBackIosOutlined
} from '@mui/icons-material'
import { format, parse } from 'date-fns'

import styles from './Gallery.module.scss'

import {
	MediaItemInterface,
	PricelistInterface,
	PricelistItemInterface,
	ReducerStoreInterface
} from '../../utils/interfaces'
import Navigation from '../../components/Navigation'
import { HOST } from '../../utils/consts'
import ChoosePackage from './components/ChoosePackage'
import {
	setStep,
	setMedia,
	setPricelist,
	setCompany,
	setGallery,
	setLoading
} from '../../store/reducers/data'
import MediaGroup from './components/MediaGroup'
import Header from '../../components/Header'
import OrderPreview from './components/OrderPreview'
import OrderFinished from './components/OrderFinished'
import Loader from '../../components/Loader'
import PhotoModal from '../../components/PhotoModal'

const Gallery = () => {
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { step, packageSelected, loading, gallery, cart } = useSelector(
		(reduxStore: ReducerStoreInterface) => reduxStore.data
	)

	const isGalleryValid = (expiresAt: null | string) => {
		if (expiresAt === null) {
			return true
		} else {
			const today = new Date().toISOString().split('T')[0]
			if (expiresAt > today) {
				return true
			} else {
				return false
			}
		}
	}

	React.useEffect(() => {
		const { slug } = params
		if (slug) {
			axios
				.get(`${HOST}/gallery/${slug}`)
				.then((result: any) => {
					if (isGalleryValid(result.data.gallery.expires_at)) {
						console.log('Gallery valid')
						const array1: MediaItemInterface[] = []
						const array2: MediaItemInterface[] = []
						const array3: MediaItemInterface[] = []
						const array4: MediaItemInterface[] = []
						// eslint-disable-next-line array-callback-return
						result.data.gallery.media.map((media: MediaItemInterface) => {
							if (media.collection_name === 'odeljenje') {
								array1.push({ ...media, attributes: { step: 1 } })
							} else if (media.collection_name === 'portreti') {
								array2.push({ ...media, attributes: { step: 2 } })
							} else if (media.collection_name === 'sa-drugom') {
								array3.push({ ...media, attributes: { step: 3 } })
							}
							array4.push({
								...media,
								collection_name: 'dodatne',
								attributes: { step: 4 }
							})
						})
						dispatch(setMedia([array1, array2, array3, array4]))
						const pricelist: PricelistInterface = {
							name: result.data.pricelist.name,
							price: result.data.pricelist.price,
							photos: result.data.pricelist.photos,
							description: result.data.pricelist.description,
							items: result.data.pricelist.options.map(
								(option: PricelistItemInterface) => {
									return {
										id: option.id,
										name: option.name,
										price: option.price
									}
								}
							)
						}
						dispatch(setPricelist(pricelist))
						if (result.data.gallery.company) {
							dispatch(
								setCompany({
									account: result.data.gallery.company.account,
									address: result.data.gallery.company.address,
									name: result.data.gallery.company.name
								})
							)
						}
						dispatch(
							setGallery({
								id: result.data.gallery.id,
								name: result.data.gallery.name,
								slug: result.data.gallery.slug,
								yearbook:
									result.data.yearbook !== null
										? {
												id: result.data.yearbook.id,
												price: result.data.yearbook.price
										  }
										: null,
								type: result.data.gallery.type,
								expires_at: result.data.gallery.expires_at
							})
						)
						dispatch(setLoading(false))
					} else {
						console.log('Gallery expired')
						dispatch(setLoading(false))
						navigate('/?expired=true')
					}
				})
				.catch((e) => {
					dispatch(setLoading(false))
					console.log("Gallery doesn't exist")
					navigate('/?error=true')
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.wrapper}>
			<Header />
			{loading && <Loader color="#ef4747" />}
			<PhotoModal />
			<h1>Galerija: {gallery.name}</h1>
			{gallery.expires_at && (
				<h3 style={{ color: '#ff0000' }}>
					(Naručivanje je moguće do{' '}
					{format(
						parse(gallery.expires_at, 'yyyy-MM-dd', new Date()),
						'dd.MM.yyyy.'
					)}
					)
				</h3>
			)}
			{step !== 6 && <Navigation />}
			{step === 0 && (
				<ChoosePackage onStepChange={(value) => dispatch(setStep(value))} />
			)}
			{[1, 2, 3, 4].includes(step) && (
				<div>
					<div className={styles.container}>
						<MediaGroup />
					</div>
					<div className={styles.buttonWrapper}>
						{packageSelected === 1 && (
							<button
								type="button"
								onClick={() => {
									dispatch(setStep(step - 1))
									window.scrollTo({ top: 0, behavior: 'smooth' })
								}}
							>
								<ArrowBackIosOutlined /> Prethodni korak
							</button>
						)}
						<button
							type="button"
							onClick={() => {
								dispatch(setStep(step + 1))
								window.scrollTo({ top: 0, behavior: 'smooth' })
							}}
							disabled={step - 1 < 3 && step - 1 === cart.length}
							className={c(
								step - 1 < 3 && step - 1 === cart.length && styles.disabled
							)}
						>
							Sledeći korak <ArrowForwardIosOutlined />
						</button>
					</div>
				</div>
			)}
			{step === 5 && <OrderPreview />}
			{step === 6 && <OrderFinished />}
		</div>
	)
}

export default Gallery
