import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ArrowOutwardOutlined } from '@mui/icons-material'

import styles from './Login.module.scss'

import Header from '../../components/Header'
import { setLoading } from '../../store/reducers/data'

const Login = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const [gallerySlug, setGallerySlug] = React.useState('')
	const [errorLoadingGallery, toggleErrorLoadingGallery] = React.useState(
		location.search.includes('error')
	)
	const [expiredGallery, toggleExpiredGallery] = React.useState(
		location.search.includes('expired')
	)

	const loginHandler = () => {
		toggleErrorLoadingGallery(false)
		toggleExpiredGallery(false)
		dispatch(setLoading(true))
		navigate(`gallery/${gallerySlug}`)
	}

	return (
		<div className={styles.wrapper}>
			<Header />
			<div className={styles.container}>
				<div className={styles.box}>
					<div className={styles.header}>
						Prijava u sistem za naručivanje fotografija
					</div>
					<div className={styles.content}>
						<div className={styles.inputWrapper}>
							<label htmlFor="gallery">
								Kod galerije <span>*</span>
								<input
									type="text"
									name="gallery"
									onChange={(event) => setGallerySlug(event.target.value)}
								/>
							</label>
						</div>
						<div className={styles.buttonWrapper}>
							<button
								type="button"
								onClick={loginHandler}
								disabled={gallerySlug.length === 0}
							>
								Prijavi se <ArrowOutwardOutlined />
							</button>
						</div>
						{errorLoadingGallery && (
							<div className={styles.error}>
								GREŠKA - Galerija sa unetim kodom ne postoji. Proverite da li je
								kod koji unosite tačan.
							</div>
						)}
						{expiredGallery && (
							<div className={styles.error}>
								Vreme za naručivanje fotografija je isteklo.
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
