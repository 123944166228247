import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './MediaGroup.module.scss'

import {
	MediaItemInterface,
	ReducerStoreInterface
} from '../../../../utils/interfaces'
import Photo from '../../../../components/Photo'
import { setLoading } from '../../../../store/reducers/data'

const MediaGroup = () => {
	const dispatch = useDispatch()
	const { step, media } = useSelector(
		(reduxStore: ReducerStoreInterface) => reduxStore.data
	)

	React.useEffect(() => {
		dispatch(setLoading(true))
		setTimeout(() => {
			dispatch(setLoading(false))
		}, media[step - 1].length * 10)
	}, [step])

	return (
		<div>
			<div className={styles.photosWrapper}>
				{media[step - 1].length > 0 &&
					media[step - 1].map(
						(mediaItem: MediaItemInterface, index: number) => (
							<Photo
								photo={{
									id: mediaItem.id,
									file_name: mediaItem.file_name,
									collection_name: mediaItem.collection_name
								}}
								step={step}
								key={index}
							/>
						)
					)}
			</div>
		</div>
	)
}

export default MediaGroup
