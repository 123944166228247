import React from 'react'
import { useSelector } from 'react-redux'

import styles from './OrderFinished.module.scss'

import { ReducerStoreInterface } from '../../../../utils/interfaces'
import Payment from '../../../../assets/images/uplatnica.jpg'

const OrderFinished = () => {
	const { totalPrice, formData, company, order } = useSelector(
		(reduxStore: ReducerStoreInterface) => reduxStore.data
	)

	return (
		<div className={styles.wrapper}>
			<h1>Uspešno ste kreirali porudžbinu, hvala vam!</h1>
			{company.name ? (
				<React.Fragment>
					<h3>U nastavku možete videti primer uplatnice</h3>
					<div className={styles.payment}>
						<img src={Payment} alt="payment" />
						<div
							className={styles.name}
						>{`${formData.name}, ${formData.address}`}</div>
						<div className={styles.purpose}>Za porudžbinu br. {order.id}</div>
						<div className={styles.recipient}>
							{`${company.name}, ${company.address}`}
						</div>
						<div className={styles.price}>
							{totalPrice.toLocaleString('sr-RS', {
								style: 'currency',
								currency: 'RSD'
							})}
						</div>
						<div className={styles.account}>{company.account}</div>
						<div className={styles.purposeB}>{order.id}</div>
					</div>
				</React.Fragment>
			) : (
				<h3>Na email koji ste uneli dobićete specifikaciju porudžbine.</h3>
			)}
		</div>
	)
}

export default OrderFinished
