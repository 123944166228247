import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { ShoppingCartCheckoutOutlined } from '@mui/icons-material'

import styles from './OrderPreview.module.scss'

import { ReducerStoreInterface } from '../../../../utils/interfaces'
import { HOST, STORAGE } from '../../../../utils/consts'
import {
	setFormData,
	setOrder,
	setPhotoModal,
	setStep,
	setTotalPrice,
	setYearbook
} from '../../../../store/reducers/data'

const OrderPreview = () => {
	const dispatch = useDispatch()
	const storeData = useSelector(
		(reduxStore: ReducerStoreInterface) => reduxStore.data
	)

	const [nameInput, setNameInput] = React.useState('')
	const [emailInput, setEmailInput] = React.useState('')
	const [phoneInput, setPhoneInput] = React.useState('')
	const [addressInput, setAddressInput] = React.useState('')
	const [noteInput, setNoteInput] = React.useState('')

	const getAdditionalCartTotal = () => {
		return storeData.cartAdditional.reduce((accumulator, value) => {
			return (
				accumulator +
				(value.quantity ? value.quantity * value.attributes.price : 0)
			)
		}, 0)
	}

	const getTotalPrice = () => {
		return storeData.pricelist.price + getAdditionalCartTotal()
	}

	React.useEffect(() => {
		dispatch(
			setTotalPrice(
				storeData.packageSelected === 1
					? getTotalPrice()
					: getAdditionalCartTotal()
			)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const orderHandler = () => {
		const orderData = {
			gallery_id: storeData.gallery.id,
			user_name: nameInput,
			user_email: emailInput,
			user_phone: phoneInput,
			user_address: addressInput,
			note: noteInput,
			photos: [...storeData.cart, ...storeData.cartAdditional],
			package_price:
				storeData.packageSelected === 1 ? storeData.pricelist.price : null,
			total_price: storeData.totalPrice,
			yearbook_id: storeData.yearbookSelected
				? storeData.gallery?.yearbook?.id
				: undefined
		}
		axios.post(`${HOST}/order/store`, orderData).then((result: any) => {
			dispatch(
				setFormData({
					name: nameInput,
					email: emailInput,
					phone: phoneInput,
					address: addressInput,
					notes: noteInput
				})
			)
			dispatch(setOrder({ id: result.data.order_id }))
			dispatch(setStep(storeData.step + 1))
		})
	}

	const calculateAdditionalPriceHandler = (checked: boolean) => {
		if (storeData.gallery.yearbook)
			dispatch(
				setTotalPrice(
					checked
						? storeData.totalPrice + storeData.gallery.yearbook.price
						: storeData.totalPrice - Number(storeData.gallery.yearbook.price)
				)
			)
		dispatch(setYearbook(checked))
	}

	return (
		<div className={styles.container}>
			<div className={styles.tableWrapper}>
				<h2>Pregled porudžbine</h2>
				{storeData.cart.length > 0 && (
					<React.Fragment>
						<h3>Uključene u paket</h3>
						<table>
							<thead>
								<tr>
									<th>Fotografija</th>
								</tr>
							</thead>
							<tbody>
								{storeData.cart.map((cartItem, index) => (
									<tr key={index}>
										<td>
											<img
												src={`${STORAGE}/${cartItem.id}/${cartItem.file_name}`}
												alt={cartItem.file_name}
												onClick={() => dispatch(setPhotoModal(cartItem))}
											/>
										</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td>UKUPNO: {storeData.pricelist.price} DIN</td>
								</tr>
							</tfoot>
						</table>
					</React.Fragment>
				)}
				<h3>Dodatne fotografije</h3>
				<table>
					<thead>
						<tr>
							<th>Fotografija</th>
							<th>Format</th>
							<th>Količina</th>
							<th>Cena</th>
							<th>Međuzbir</th>
						</tr>
					</thead>
					<tbody>
						{storeData.cartAdditional.map((cartItem, index) => (
							<tr key={index}>
								<td>
									<img
										src={`${STORAGE}/${cartItem.id}/${cartItem.file_name}`}
										alt={cartItem.file_name}
										onClick={() => dispatch(setPhotoModal(cartItem))}
									/>
								</td>
								<td>{cartItem?.attributes?.name}</td>
								<td>{cartItem.quantity}</td>
								<td>{cartItem?.attributes?.price}</td>
								<td>
									{cartItem.quantity
										? cartItem.quantity * cartItem?.attributes?.price
										: 0}
								</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={5}>UKUPNO: {getAdditionalCartTotal()} DIN</td>
						</tr>
					</tfoot>
				</table>
				<div className={styles.additional}>
					<h3>Dodatne opcije za poručivanje:</h3>
					{storeData.gallery.yearbook !== null && (
						<div>
							<label htmlFor="yearbook_id">
								<input
									type="checkbox"
									name="yearbook_id"
									onChange={(event) =>
										calculateAdditionalPriceHandler(event.target.checked)
									}
								/>
								Almanah/godišnjak ({storeData.gallery.yearbook.price} DIN)
							</label>
						</div>
					)}
				</div>
				<div className={styles.orderTotal}>
					<h3>UKUPAN IZNOS PORUDŽBINE:</h3>
					<h3>{storeData.totalPrice} DIN</h3>
				</div>
			</div>
			<hr />
			<div className={styles.formWrapper}>
				<h3>Podaci o naručiocu:</h3>
				<div className={styles.inputWrapper}>
					<label htmlFor="name">
						Ime i prezime <span>*</span>
						<input
							type="text"
							name="name"
							onChange={(event) => setNameInput(event.target.value)}
						/>
					</label>
				</div>
				<div className={styles.inputWrapper}>
					<label htmlFor="email">
						Email <span>*</span>
						<input
							type="email"
							name="email"
							onChange={(event) => setEmailInput(event.target.value)}
						/>
					</label>
				</div>
				<div className={styles.inputWrapper}>
					<label htmlFor="phone">
						Telefon <span>*</span>
						<input
							type="text"
							name="phone"
							onChange={(event) => setPhoneInput(event.target.value)}
						/>
					</label>
				</div>
				<div className={styles.inputWrapper}>
					<label htmlFor="address">
						Adresa <span>*</span>
						<input
							type="text"
							name="address"
							onChange={(event) => setAddressInput(event.target.value)}
						/>
					</label>
				</div>
				<div className={styles.textareaWrapper}>
					<label htmlFor="note">
						Ime i prezime deteta / Beleška <span>*</span>
						<textarea
							name="note"
							onChange={(event) => setNoteInput(event.target.value)}
						/>
					</label>
				</div>
			</div>
			<div className={styles.buttonWrapper}>
				<button
					type="button"
					onClick={orderHandler}
					disabled={
						nameInput.length === 0 ||
						emailInput.length === 0 ||
						phoneInput.length === 0 ||
						addressInput.length === 0 ||
						noteInput.length === 0
					}
				>
					Završi porudžbinu <ShoppingCartCheckoutOutlined />
				</button>
			</div>
		</div>
	)
}

export default OrderPreview
