import React from 'react'
import { Route, HashRouter, Routes } from 'react-router-dom'

import './App.css'
import Login from './pages/Login'
import Gallery from './pages/Gallery'

const App = () => {
	React.useEffect(() => {
		document.title = 'Naručivanje fotografija - Fotograf Novi Sad'
	}, [])

	return (
		<div className="App">
			<HashRouter>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/gallery/:slug" element={<Gallery />} />
				</Routes>
			</HashRouter>
		</div>
	)
}

export default App
