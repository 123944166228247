import React from 'react'
import { MoonLoader } from 'react-spinners'

import styles from './Loader.module.scss'

const Loader = ({ color }: { color: string }) => (
	<div className={styles.wrapper}>
		<div className={styles.loaderContainer}>
			<MoonLoader color={color} size={52} loading={true} />
		</div>
	</div>
)

export default Loader
