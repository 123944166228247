import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowForwardIosOutlined } from '@mui/icons-material'

import styles from './ChoosePackage.module.scss'

import { setPackage, setStep } from '../../../../store/reducers/data'
import { ReducerStoreInterface } from '../../../../utils/interfaces'
import { format, parse } from 'date-fns'

interface ChoosePackageInterface {
	onStepChange: (step: number) => void
}

const ChoosePackage = ({ onStepChange }: ChoosePackageInterface) => {
	const dispatch = useDispatch()

	const { pricelist, gallery, packageSelected } = useSelector(
		(reduxStore: ReducerStoreInterface) => reduxStore.data
	)

	const [packageSelectedState, setPackageSelectedState] = React.useState(0)

	React.useEffect(() => {
		if (gallery.type === 2) {
			dispatch(setPackage(2))
			dispatch(setStep(4))
		}
		setPackageSelectedState(packageSelected)
	}, [gallery])

	return (
		<React.Fragment>
			<div className={styles.wrapper}>
				<h1>Dobrododošli u galeriju</h1>
				<h2>Odaberite željenu opciju:</h2>
				<div className={styles.item}>
					<div className={styles.inputWrapper}>
						<input
							type="radio"
							name="package"
							onChange={() => {
								setPackageSelectedState(1)
							}}
							checked={packageSelectedState === 1}
						/>
					</div>
					<div className={styles.description}>
						<h3>PAKET + DODATNE FOTOGRAFIJE</h3>
						<div>{pricelist.description}</div>
						<div>
							<ul>
								<li>Cena paketa: {pricelist.price}</li>
							</ul>
						</div>
					</div>
				</div>
				<div className={styles.item}>
					<div className={styles.inputWrapper}>
						<input
							type="radio"
							name="package"
							onChange={() => {
								setPackageSelectedState(2)
							}}
							checked={packageSelectedState === 2}
						/>
					</div>
					<div className={styles.description}>
						<h3>DODATNE FOTOGRAFIJE</h3>
						<div>
							Slobodan odabir fotografija bez ograničenja u formatima koje
							odaberete.
							<ul>
								{pricelist.items.map((item, index) => (
									<li key={index}>{`${item.name} - ${item.price} din.`}</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.buttonWrapper}>
				{packageSelectedState !== 0 && (
					<button
						type="button"
						onClick={() => {
							dispatch(setPackage(packageSelectedState))
							onStepChange(packageSelectedState === 1 ? 1 : 4)
						}}
					>
						Pređi na sledeći korak <ArrowForwardIosOutlined />
					</button>
				)}
			</div>
		</React.Fragment>
	)
}

export default ChoosePackage
