import styles from './Hedaer.module.scss'

import Logo from '../../assets/images/logo.png'

const Header = () => {
	return (
		<nav className={styles.nav}>
			<img src={Logo} alt="logo" />
		</nav>
	)
}

export default Header
