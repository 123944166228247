import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import c from 'classnames'

import styles from './Navigation.module.scss'
import { ReducerStoreInterface } from '../../utils/interfaces'
import { setStep } from '../../store/reducers/data'

const Navigation = () => {
	const dispatch = useDispatch()
	const { step, packageSelected, gallery } = useSelector(
		(reduxStore: ReducerStoreInterface) => reduxStore.data
	)

	return (
		<div className={styles.wrapper}>
			<div className={styles.line}></div>
			<div
				className={c(
					styles.item,
					step === 0 && styles.selected,
					gallery.type === 2 && styles.disabled
				)}
				onClick={() => dispatch(setStep(0))}
			>
				<div>ODABIR PAKETA</div>
			</div>
			{packageSelected === 1 && (
				<React.Fragment>
					<div
						className={c(
							styles.item,
							step === 1 && styles.selected,
							step < 1 && styles.disabled
						)}
						onClick={() => step >= 1 && dispatch(setStep(1))}
					>
						<div>ODABIR 1</div>
					</div>
					<div
						className={c(
							styles.item,
							step === 2 && styles.selected,
							step < 2 && styles.disabled
						)}
						onClick={() => step >= 2 && dispatch(setStep(2))}
					>
						<div>ODABIR 2</div>
					</div>
					<div
						className={c(
							styles.item,
							step === 3 && styles.selected,
							step < 3 && styles.disabled
						)}
						onClick={() => step >= 3 && dispatch(setStep(3))}
					>
						<div>ODABIR 3</div>
					</div>
				</React.Fragment>
			)}
			<div
				className={c(
					styles.item,
					step === 4 && styles.selected,
					step < 4 && styles.disabled
				)}
				onClick={() => step >= 4 && dispatch(setStep(4))}
			>
				<div>{gallery.type === 1 ? 'DODATNE FOTO.' : 'ODABIR FOTOGRAFIJA'}</div>
			</div>
			<div
				className={c(
					styles.item,
					step === 5 && styles.selected,
					step < 5 && styles.disabled
				)}
				onClick={() => step >= 5 && dispatch(setStep(5))}
			>
				<div>PREGLED KORPE</div>
			</div>
		</div>
	)
}

export default Navigation
